export default {
	万: "หมื่น",
	确认: "ยืนยัน",
	取消: "ยกเลิก",
	账号: "บัญชีผู้ใช้",
	密码: "รหัสผ่าน",
	确认密码: "ยืนยันรหัสผ่าน",
	邀请码: "รหัสเชิญ",
	公益榜: "ตารางการกุศล",
	音乐榜: "ตารางเพลง",
	电影榜: "ตารางภาพยนตร์",
	财经榜: "ตารางการเงิน",
	直播榜: "ตารางการถ่ายทอดสด",
	密码错误: "รหัสผ่านไม่ถูกต้อง",
	选红色: "เลือกสีแดง",
	选蓝色: "เลือกสีน้ำเงิน",
	币单位: "฿",
	donation: {
		title: "บริจาค",
		total_donations_amount: "ยอดรวมบริจาคของแพลตฟอร์ม",
		my_donation_amount: "จำนวนเงินบริจาคของฉัน",
		donation_count: "จำนวนครั้งของการบริจาค",
		num: "ครั้ง",
		invitations_sent: "เชิญเพื่อนมาร่วม",
		donate_now_button: "บริจาคทันที",
		donation_amount_input: "กรอกจำนวนเงินบริจาค",
	},
	tabbar: {
		home: "หน้าแรก",
		welfare: "ตารางการกุศล",
		antifraud: "ต่อต้านการฉ้อโกง",
		my: "ของฉัน",
	},
	withdraw: {
		current_account_balance: "ยอดเงินในบัญชีปัจจุบัน ",
		placeholder1: "กรุณากรอกจำนวนเงินที่ถอน",
		bank_card_info: "ข้อมูลบัตรธนาคาร",
		tip1: "ยังไม่ได้ผูกกับบัตรธนาคาร，ผูกตอนนี้",
		receiving_bank: "บัตรธนาคารผู้รับเงิน",
		receiving_account: "บัญชีผู้รับเงิน",
		receiving_name: "ชื่อผู้รับเงิน",
		placeholder2: "กรุณากรอกรหัสผ่านการถอนเงิน",
		submit_application: "ยืนยันการถอน",
		hint: "เคล็ดลับดีๆ",
		withdraw_time: "เวลาถอนเงิน",
		tip2: "คุณยังไม่ได้ตั้งรหัสผ่านการถอนเงิน，คุณต้องไปที่การตั้งค่า？",
		toast1: "รหัสผ่านการถอนคือ 6 หลัก",
		toast2: "กรุณาผูกบัตรธนาคารก่อน",
	},
	records: {
		phase: "ระยะเวลา",
		push_red: "แนะนำสีแดง",
		push_blue: "แนะนำสีฟ้า",
		odd: "ราคาต่อรอง",
		opened: "เริ่มเปิด",
		not_opened: "ยังไม่ได้เปิด",
		result: "ผลลัพธ์",
		red_victory: "สีแดงชนะ",
		blue_victory: "สีฟ้าชนะ",
		red_profit: "กำไรฝั่งแดง",
		blue_profit: "กำไรฝั่งสีน้ำเงิน",
	},
	recharge: {
		placeholder1: "กรุณากรอกจำนวนเงินที่เติม",
		placeholder2: "กรุณาอัพโหลดการเติมเงินของคุณ",
		upload_successfully: "อัปโหลดสำเร็จ",
		tip1: "ขนาดไฟล์ต้องไม่เกิน 6m",
		submitted_successfully: "ส่งเรียบร้อยแล้ว",
		tip2: "คุณมีการเติมเงินที่อยู่ระหว่างการตรวจสอบ，กรุณารอสักครู่",
	},
	bank: {
		bank_card_management: "การจัดการบัตรธนาคาร",
		placeholder1: "กรุณากรอกชื่อธนาคาร",
		placeholder2: "กรุณากรอกหมายเลขบัญชีผู้รับเงิน",
		placeholder3: "กรุณากรอกชื่อผู้รับเงิน",
		label1: "ชื่อธนาคาร",
		label2: "บัญชีผู้รับเงิน",
		label3: "ชื่อผู้รับเงิน",
		add_successfully: "เพิ่มเรียบร้อยแล้ว",
		add_failure: "เพิ่มล้มเหลว",
		card_number_error: "หมายเลขบัตรไม่ถูกต้องตามจำนวนหลักที่กำหนด",
	},
	safety: {
		text1: "เปลี่ยนรหัสผ่านการถอน",
		text2: "กรุณากรอกรหัสผ่านเดิม",
		text3: "กรุณากรอกรหัสผ่านใหม่",
		text4: "กรุณายืนยันรหัสผ่านใหม่",
		modified_successfully: "แก้ไขเรียบร้อยแล้ว",
		old_password_error: "รหัสผ่านเดิมไม่ถูกต้อง",
		title: "ศูนย์ต่อต้านการโกง",
	},
	register: {
		title: "การลงทะเบียนผู้ใช้",
		input_placeholder1: "กรุณากรอกชื่อผู้ใช้",
		input_placeholder2: "กรุณากรอกรหัสผ่าน",
		input_placeholder3: "กรุณากรอกรหัสผ่านอีกครั้ง",
		input_placeholder4: "กรุณากรอกรหัสเชิญ",
		register: "ลงทะเบียน",
		text1: "มีบัญชีอยู่แล้ว",
		to_login: "เข้าสู่ระบบทันที",
		toast1: "การป้อนรหัสผ่านทั้งสองไม่สอดคล้องกัน",
	},
	index: {
		title: "มูลนิธิกระจกเงา",
		text1: "รายละเอียดการประกาศ",
		period_time: "รอ",
		deadline_participation: "กำหนดเวลาการเข้าร่วม",
		closed: "ปิด",
		deadline: "กำหนดเวลาการออกรางวัล",
		min_bet_amount: "จำนวนเงินลงทุนขั้นต่ำ",
		number_one: "รายการหมายเลข",
		red_team: "ทีมสีแดง",
		blue_team: "ทีมสีน้ำเงิน",
		red_team_odds: "อัตราต่อรองทีมสีแดง",
		blue_team_odds: "อัตราต่อรองทีมสีน้ำเงิน",
		all: "ทั้งหมด",
		get_involved_now: "เข้าร่วมเลย",
		placeholder1: "กรุณากรอกจำนวนเงินเดิมพัน",
	},
	login: {
		title: "กรุณาเข้าสู่ระบบ",
		tip1: "กรุณากรอกชื่อผู้ใช้",
		tip2: "กรุณากรอกรหัสผ่าน",
		forget_password: "ลืมรหัสผ่าน",
		submit: "ส่ง",
		text1: "ยังไม่มีบัญชี",
		to_register: "ไปลงทะเบียน",
		change_login_password: "เปลี่ยนรหัสผ่านเข้าสู่ระบบ",
	},
	user: {
		withdraw_password: "รหัสถอนเงิน",
		login_password: "รหัสผ่านการเข้าใช้งาน",
		account_security: "ความปลอดภัยของบัญชี",
		rejected: "ถูกปฏิเสธ",
		passed: "ผ่าน",
		pending_review: "รอการตรวจสอบ",
		state: "สถานะการ",
		time: "เวลา",
		amount: "จำนวน",
		types: "พิมพ์",
		withdraw_funds: "ถอน",
		recharge: "เติมเงิน",
		no_more: "ไม่มีอีกแล้ว",
		loading: "กำลังโหลด...",
		account: "บัญชี",
		invitation_code: "รหัสการเชิญ",
		balance: "จำนวน",
		endowment: "บริจาค",
		withdraw_cash_now: "ถอนตอนนี้",
		go_recharge: "เติมเงิน",
		tiktok_shop: "TikTokมอลล์",
		security_center: "ศูนย์รักษาความปลอดภัย",
		bank_record: "บันทึกการฝากและถอนเงิน",
		transaction_records: "บันทึกการทำธุรกรรม",
		bank_card: "บัตรเครดิตธนาคาร",
		language: "ภาษา",
		logout: "ออกจากระบบ",
		go_setting: "ไปที่ การตั้งค่า"
	},
};
