export const IMAGE_URL = "https://api.tiktokpkk.com/storage/";
export const PUSHER_APP_KEY = "oj3zRxwGkcIaLFEKPrQ5mpCYl1dAhOT2"; //泰国
// export const PUSHER_APP_KEY =
// 	process.env.VUE_APP_LANG === "vi_VN"
// 		? "oj3zRxwGkcIaLFEKPrQ5mpCYl1dAhOT2"
// 		: "GEQTrDY0bnIXHBFC8ZofP9WVvUi7RAL1";
export const PUSHER_APP_CLUSTER = "mt1";
export const PUSHER_HOST = "api.tiktokpk.com";
export const PUSHER_PORT = "6001";
export const PUSHER_SCHEME = "https";
